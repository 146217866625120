/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { faArrowRightToBracket } from '@fortawesome/pro-regular-svg-icons'

import Button from '@/components/ui/atoms/Button'

export default function NotFound() {
  const router = useRouter()
  useEffect(() => {
    router.push('/')
  }, [])
  return (
    <main className="flex min-h-screen flex-col items-center justify-center">
      <div>
        <div className="text-center">
          <h1 className="text-9xl text-disabled ">404</h1>
          <h2>¡Esta página no existe!</h2>
          <h6>
            Lo sentimos, pero la página que estás buscando no fué encontrada.
          </h6>
        </div>
        <div className="my-6 w-full space-y-4">
          <div>
            <h6 className="mb-2 text-center font-bold">
              Pero intenta buscar algo más
            </h6>
          </div>
          <div className="flex flex-col justify-center md:flex-row md:space-x-4">
            <Link href="/" passHref>
              <Button
                color="dark"
                size="lg"
                icon={faArrowRightToBracket}
                fullWidth
              >
                Volver a Luegopago
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </main>
  )
}

NotFound.getLayout = (page: React.ReactNode) => page
